/**
 * Polyfill von https://developer.mozilla.org/en-US/docs/Web/API/Element/toggleAttribute
 */
export default function polyfillToggleAttribute() {
    /* eslint-disable */
    if (!Element.prototype.toggleAttribute) {
        Element.prototype.toggleAttribute = function(name, force) {
            if(force !== void 0) force = !!force

            if (this.hasAttribute(name)) {
                if (force) return true;

                this.removeAttribute(name);
                return false;
            }
            if (force === false) return false;

            this.setAttribute(name, "");
            return true;
        };
    }
    /* eslint-enable */
}
