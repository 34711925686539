export default () => {
    const toast = document.querySelector('.js-toast');

    if (toast) {
        // Toast ist initial versteckt.
        if (!document.cookie.split('; ').find((row) => row.startsWith('toast'))) {
            // Overlay anzeigen
            toast.classList.remove('is-hidden');

            // Event-Listener auf Schließen-Button legen und Cookie setzen,
            // damit das Overlay 3 Tage nicht mehr angezeigt wird
            const closingElements = document.querySelectorAll('.js-toast-closebutton');

            closingElements.forEach((element) => {
                element.addEventListener('click', (event) => {
                    event.preventDefault();

                    toast.classList.add('is-hidden');

                    // Cookie setzen
                    const now = new Date();
                    const expire = new Date();

                    expire.setFullYear(now.getFullYear());
                    expire.setMonth(now.getMonth());
                    expire.setDate(now.getDate() + 3);
                    expire.setHours(0);
                    expire.setMinutes(0);
                    expire.setSeconds(0);

                    document.cookie = `toast=true; expires=${expire}; Secure; path=/;`;

                    if (event.target.tagName === 'A') {
                        window.location = event.target.getAttribute('href');
                    }
                });
            });
        }
    }
};
