export default () => {
    const dropdownLists = document.querySelectorAll('.js-dropdown-list');

    if (!dropdownLists) return;

    function setListHeight(dropdown, button, closed = false, initial = false) {
        const textList = button.nextElementSibling;

        if (!textList) return;

        if (initial && dropdown.classList.contains('open')) {
            textList.style.height = `${textList.scrollHeight}px`;
            return;
        }

        if (closed) {
            textList.style.height = '0px';
            return;
        }

        if (!closed && !initial) {
            textList.style.height = `${textList.scrollHeight}px`;
        }
    }

    function toggleDropdown(event) {
        const button = event.target;

        if (!button) return;

        const dropdown = button.parentElement;

        if (!dropdown) return;

        if (dropdown.classList.contains('open')) {
            setListHeight(dropdown, button, true);

            dropdown.classList.remove('open');
        } else {
            setListHeight(dropdown, button);

            dropdown.classList.add('open');
        }
    }

    dropdownLists.forEach((dropdownlist) => {
        const dropdowns = dropdownlist.querySelectorAll('.js-dropdown');

        dropdowns.forEach((dropdown) => {
            const button = dropdown.querySelector('button');

            if (!button) return;

            setListHeight(dropdown, button, false, true);

            button.addEventListener('click', toggleDropdown);
        });
    });
};
