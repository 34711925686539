export default () => {
    const adOverlay = document.querySelector('.js-adplace-backdrop');

    if (adOverlay) {
        const adId = adOverlay.querySelector('.g-single').classList.value.replace(' ', '-');

        // Overlay ist initial versteckt.
        // Wurde dieses Overlay heute schon angezeigt?
        if (!document.cookie.split('; ').find((row) => row.startsWith(adId))) {
            // Overlay anzeigen
            const body = document.querySelector('body');
            adOverlay.classList.remove('is-hidden');
            body.classList.add('has-overlay');

            // Event-Listener auf Schließen-Button legen und Cookie setzen, damit das Overlay
            // mit dieser ID heute nicht mehr angezeigt wird
            const closingElements = document.querySelectorAll('.js-adplace-backdrop-close');

            closingElements.forEach((element) => {
                element.addEventListener('click', () => {
                    adOverlay.classList.add('is-hidden');
                    body.classList.remove('has-overlay');

                    // Cookie setzen
                    const now = new Date();
                    const expire = new Date();

                    expire.setFullYear(now.getFullYear());
                    expire.setMonth(now.getMonth());
                    expire.setDate(now.getDate() + 1);
                    expire.setHours(0);
                    expire.setMinutes(0);
                    expire.setSeconds(0);

                    document.cookie = `${adId}=true; expires=${expire}; Secure`;
                });
            });
        }
    }
};
