/**
 * Wenn eine checked Radiobox angeklickt wird, wird sie deaktiviert.
 */

export default function deactivateRadio() {
    const radioboxes = document.querySelectorAll('.js-deactivate-radio');

    radioboxes.forEach((radio) => {
        radio.addEventListener('click', (event) => {
            const {target} = event;
            if (target.classList.contains('js-deactivate-radio')) {
                if (target.classList.contains('checked')) {
                    target.checked = false;
                    target.classList.remove('checked');
                    if (target.classList.contains('js-radio-submit')) {
                        target.closest('form').submit();
                    }
                } else {
                    radioboxes.forEach((radiobox) => {
                        radiobox.classList.remove('checked');
                    });
                    target.classList.add('checked');
                }
            }
        });
    });
}
