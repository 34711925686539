export default function toggleToc() {
    const toggleButton = document.querySelector('.js-toggle-toc');

    if (toggleButton) {
        toggleButton.addEventListener('click', () => {
            const wrapper = toggleButton.closest('.js-toc-wrapper');
            wrapper.classList.toggle('is-open');

            if (wrapper.classList.contains('is-open')) {
                toggleButton.setAttribute('aria-expanded', true);
            } else {
                toggleButton.setAttribute('aria-expanded', false);
            }
        });
    }
}
