export default function stickySidebar() {
    const sidebars = document.querySelectorAll('.js-sidebar');
    const sidebarElements = document.querySelectorAll('.js-sidebar-link');
    const sections = document.querySelectorAll('.js-sidebar-section');
    const isMobile = window.innerWidth <= 992;

    if (sidebars.length === 0) {
        return;
    }

    /**
     * Get Swiper item index
     * @param swiper
     * @param element
     * @returns {null}
     */
    function getIndexOfSlide(swiper, element) {
        let elementIndex = null;
        swiper.slides.forEach((swiperSlide, i) => {
            if (swiperSlide === element) {
                elementIndex = i;
            }
        });

        return elementIndex;
    }

    /**
     * Initialize Swiper on mobile or horizontal sidebar only
     * @param sidebar
     */
    function initSwiper(sidebar) {
        // eslint-disable-next-line no-new,no-undef
        const swiperSidebar = new Swiper(sidebar, {
            freeMode: true,
            slidesPerView: 'auto',
            allowTouchMove: true,
            normalizeSlideIndex: false,
            centeredSlides: true,
            centeredSlidesBounds: true,
        });

        swiperSidebar.on('slideChange', (swiper) => {
            if (swiper.isBeginning) {
                sidebar.classList.remove('gradient--left');
            } else if (swiper.isEnd) {
                sidebar.classList.remove('gradient--right');
            } else {
                sidebar.classList.add('gradient--right');
                sidebar.classList.add('gradient--left');
            }
        });
    }

    /**
     * Slide to active element, if the sidebar is an initialized Swiper
     * @param element
     * @param parentSidebar
     */
    function slideToActiveElement(element, parentSidebar) {
        if (parentSidebar.swiper) {
            const index = getIndexOfSlide(parentSidebar.swiper, element);
            if (index !== parentSidebar.swiper.activeIndex) {
                parentSidebar.swiper.slideTo(index);
            }
        }
    }

    /**
     * Sync the sidebar menu with the scroll position and the visible section
     */
    function syncSidebarWithScrollPos() {
        sections.forEach((section) => {
            const rect = section.getBoundingClientRect();
            if (rect.y > (-(rect.height / 2)) && rect.y < window.innerHeight / 2) {
                sidebarElements.forEach((element) => {
                    if (element.hash === `#${section.id}`) {
                        element.classList.add('active');
                        slideToActiveElement(element.parentElement, element.closest('.js-sidebar'));
                    } else {
                        element.classList.remove('active');
                    }
                });
            }
        });
    }

    sidebars.forEach((sidebar) => {
        if (window.Swiper && sidebar.classList.contains('js-sidebar-horizontal')) {
            if (!sidebar.classList.contains('js-sidebar-mobile') || isMobile) {
                initSwiper(sidebar);
            }
        }
    });

    sidebarElements.forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            const headline = document.querySelector(event.target.hash);
            if (headline) {
                headline.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
        });
    });

    window.addEventListener('scroll', syncSidebarWithScrollPos);
}
