export default () => {
    const alphabet = document.querySelector('.js-alphabet');

    if (!alphabet && !window.Swiper) {
        return;
    }

    // eslint-disable-next-line no-new,no-undef
    const swiperAlphabet = new Swiper(alphabet, {
        freeMode: true,
        slidesPerView: 'auto',
        allowTouchMove: true,
        normalizeSlideIndex: false,
        centeredSlides: true,
        centeredSlidesBounds: true,
    });

    swiperAlphabet.on('slideChange', (swiper) => {
        if (swiper.isBeginning) {
            alphabet.classList.remove('gradient--left');
        } else if (swiper.isEnd) {
            alphabet.classList.remove('gradient--right');
        } else {
            alphabet.classList.add('gradient--right');
            alphabet.classList.add('gradient--left');
        }
    });
};
