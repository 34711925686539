export default function dynamicCardCarouselContent(wrapperClass, carouselClass, restEndPoint) {
    const wrapper = document.querySelector(wrapperClass);
    let carousel = null;

    if (document.querySelector(carouselClass)) {
        carousel = document.querySelector(carouselClass).swiper;
    }

    function insertItems(items) {
        items.forEach((item) => {
            const domParser = new DOMParser().parseFromString(item, 'text/html');
            if (carousel) {
                Array.prototype.forEach.call(domParser.body.childNodes, (node) => {
                    carousel.prependSlide(node);
                });
            }
        });
        carousel.slideTo(0);
    }

    if (wrapper && !wrapper.hasAttribute('data-loadmore')) {
        let error = false;
        const spinner = wrapper.querySelector('.js-loading-spinner');
        spinner.classList.add('loading');

        if (carousel) {
            fetch(restEndPoint)
                .then((response) => {
                    const contentType = response.headers.get('content-type');
                    if (!contentType || !contentType.includes('application/json')) {
                        error = true;
                    }
                    return response.json();
                })
                .then((data) => {
                    if (!error) {
                        insertItems(data.items);
                        spinner.classList.remove('loading');
                    }
                });
        }
    }
}
