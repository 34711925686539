/**
 * collapsible-single schließt im Gegensatz zu collapsible alle anderen Collapsibles wenn eines geöffnet wird
 */
export default () => {
    const collapsibleToggles = document.querySelectorAll('.js-collapsible-single-toggle');

    const openCollapsible = (collapsibleButton) => {
        const openCollabsible = document.querySelector('.js-collapsible-single.open');
        const collapsible = collapsibleButton.closest('.js-collapsible-single');

        if (openCollabsible && openCollabsible !== collapsible) {
            openCollabsible.classList.remove('open');
            openCollabsible.setAttribute('aria-expanded', 'false');
        }

        collapsible.classList.toggle('open');
        if (collapsible.classList.contains('open')) {
            collapsible.setAttribute('aria-expanded', 'true');
        } else {
            collapsible.setAttribute('aria-expanded', 'false');
        }
    };

    const toggleCollapsible = (event) => {
        event.preventDefault();
        openCollapsible(event.target);
    };

    collapsibleToggles.forEach((collapsibleToggle) => {
        collapsibleToggle.addEventListener('click', toggleCollapsible);

        if (window.location.hash && window.location.hash.replace('#', '') === collapsibleToggle.id) {
            window.scrollTo({
                top: collapsibleToggle.getBoundingClientRect().top + window.pageYOffset - 150,
            });

            openCollapsible(collapsibleToggle);
        }
    });
};
