/**
 * Schickt das nächstgelegene Formular ab, wenn sich die Auswahl in einem Select ändert
 */

export default function changeSubmit() {
    const selects = document.querySelectorAll('.js-select-submit');
    const radios = document.querySelectorAll('.js-radio-submit');

    selects.forEach((select) => {
        select.addEventListener('change', () => {
            select.closest('form').submit();
        });
    });

    radios.forEach((radio) => {
        radio.addEventListener('change', (event) => {
            const {target} = event;
            target.closest('form').submit();
        });
    });
}
