export default () => {
    const authorLinks = document.querySelectorAll('.js-open-authorbox');

    authorLinks.forEach((link) => {
        link.addEventListener('click', () => {
            const targetId = link.getAttribute('href');
            const target = document.querySelector(targetId);

            window.scrollTo(target);

            if (!target.classList.contains('open')) {
                target.querySelector('.js-collapsible-toggle').click();
            }
        });
    });
};
