export default function jobList() {
    const wrapper = document.querySelector('.js-joblist');
    let error = false;

    function replaceItems(jobs) {
        const items = wrapper.querySelectorAll('.js-joblist-item');
        items.forEach((item, index) => {
            const link = item.querySelector('.js-joblist-link');
            const headline = item.querySelector('.js-joblist-headline');
            const subline = item.querySelector('.js-joblist-subline');
            link.href = jobs[index].link;
            headline.innerText = jobs[index].title;
            if (jobs[index].subline) {
                subline.innerText = '';
                const domParser = new DOMParser().parseFromString(jobs[index].subline, 'text/html');
                Array.prototype.forEach.call(domParser.body.childNodes, (node) => {
                    subline.appendChild(node);
                });
            }
        });
    }

    if (wrapper) {
        // eslint-disable-next-line no-undef
        fetch(`${juve.rest_url}/juve/v1/jobs/`)
            .then((response) => {
                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {
                    error = true;
                }
                return response.json();
            })
            .then((data) => {
                if (!error) {
                    replaceItems(data);
                }
            });
    }
}
