export default () => {
    const rankingTopic = document.querySelector('.js-ranking-vertical');
    const rankingAZ = document.querySelector('.js-ranking-horizontal');
    let topicGroupButtons;
    let topicGroups;
    let topicContent;
    let topicContentButton;
    let rankingLetters;
    let rankingLetterGroups;

    function deactivateListItems(list) {
        list.forEach((item) => {
            item.classList.remove('active');
        });
    }
    function activateListItems(list) {
        list.forEach((item) => {
            item.classList.add('active');
        });
    }

    function switchTopic(eventTarget) {
        deactivateListItems(topicGroupButtons);
        deactivateListItems(topicGroups);
        const target = rankingTopic.querySelector(`#${eventTarget.dataset.target}`);
        if (target) {
            eventTarget.classList.add('active');
            topicContent.classList.add('active');
            target.classList.add('active');
        }
    }

    if (rankingTopic) {
        topicGroupButtons = rankingTopic.querySelectorAll('.js-ranking-topicgroupbutton');
        topicGroups = rankingTopic.querySelectorAll('.js-ranking-topicgroup');
        topicContent = rankingTopic.querySelector('.js-ranking-content');
        topicContentButton = rankingTopic.querySelector('.js-ranking-contentbutton');

        topicGroupButtons.forEach((topicGroupButton) => {
            topicGroupButton.addEventListener('click', (event) => {
                switchTopic(event.target);
            });
        });

        if (window.matchMedia('(min-width: 768px)').matches) {
            if (topicGroupButtons.length > 0) {
                switchTopic(topicGroupButtons[0]);
            }
        }

        if (topicContentButton) {
            topicContentButton.addEventListener('click', () => {
                topicContent.classList.remove('active');
            });
        }
    }

    if (rankingAZ) {
        rankingLetters = rankingAZ.querySelectorAll('.js-ranking-letter');
        rankingLetterGroups = rankingAZ.querySelectorAll('.js-ranking-lettergroup');

        rankingLetters.forEach((rankingLetter) => {
            rankingLetter.addEventListener('click', () => {
                const checkedLetter = rankingAZ.querySelector('.js-ranking-letter:checked');
                if (checkedLetter) {
                    deactivateListItems(rankingLetterGroups);
                    const target = rankingAZ.querySelector(`#${checkedLetter.dataset.target}`);
                    if (target) {
                        target.classList.add('active');
                    }
                } else {
                    activateListItems(rankingLetterGroups);
                }
            });
        });
    }
};
