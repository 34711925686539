/**
 * Ein- und ausblenden von Facts in der Liste des Fact-Blocks
 */
export default function factBlock() {
    const factBlocks = document.querySelectorAll('.js-fact-block');

    factBlocks.forEach((block) => {
        const facts = block.querySelectorAll('.js-fact');
        const showMoreButton = block.querySelector('.js-facts-show-more');
        const showLessButton = block.querySelector('.js-facts-show-less');

        if (facts.length) {
            showMoreButton.addEventListener('click', () => {
                facts.forEach((fact) => {
                    fact.removeAttribute('hidden');
                });

                showLessButton.removeAttribute('hidden');
                showMoreButton.setAttribute('hidden', 'hidden');
                facts[0].focus();
            });

            showLessButton.addEventListener('click', () => {
                facts.forEach((fact) => {
                    fact.setAttribute('hidden', 'hidden');
                });

                showLessButton.setAttribute('hidden', 'hidden');
                showMoreButton.removeAttribute('hidden');
                showMoreButton.focus();
            });
        }
    });
}
