export default () => {
    const carousel = document.querySelectorAll('.js-carousel');

    if (!carousel && !window.Swiper) {
        return;
    }

    carousel.forEach((element) => {
        let breakpoints = {};

        // eslint-disable-next-line radix
        const datasetSlides = element.dataset.carouselSlides ? parseInt(element.dataset.carouselSlides) : 1;

        if (datasetSlides === 2) {
            breakpoints = {
                768: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
            };
        }

        if (datasetSlides > 2) {
            breakpoints = {
                768: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                1200: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
            };
        }

        let spaceBetween = 40;

        if (datasetSlides > 2) {
            spaceBetween = 20;
        }

        // eslint-disable-next-line no-unused-vars,no-undef
        const swiper = new Swiper(element, {
            slidesPerView: 1,
            centeredSlides: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            a11y: {
                prevSlideMessage: 'Zurück',
                nextSlideMessage: 'Nächste',
            },
            spaceBetween,
            breakpoints,
        });
    });
};
