export default () => {
    const referrerLinks = document.querySelectorAll('.js-referrer-redirect');
    if (!referrerLinks) return;

    referrerLinks.forEach((link) => {
        const {redirectPath} = link.dataset;
        const paginationPage = `/${redirectPath}/page/`;

        if (document.referrer.includes(paginationPage)) {
            link.setAttribute('href', document.referrer);
        }
    });
};
