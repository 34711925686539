export default function archiveMore() {
    const archiveMoreButton = document.querySelector('.js-archive-more-button');
    const archivePostWrapper = document.querySelector('.js-archive-posts');
    let doRemoveMoreButton = false;

    if (!archiveMoreButton) {
        return;
    }

    function removeMoreButton() {
        // wenn IE11 mal nicht mehr unterstützt werden soll, darf man hieraus gerne ein
        // `archiveMoreButton.parentNode.remove();` machen
        archiveMoreButton.parentNode.parentNode.removeChild(archiveMoreButton.parentNode);
    }

    function toggleMoreButton() {
        archiveMoreButton.toggleAttribute('disabled');
        archiveMoreButton.classList.toggle('active-query');
    }

    function getPostIDs(targetClass, postsPerPage) {
        const loadItems = document.querySelectorAll(`.${targetClass}`);
        const counter = loadItems.length >= postsPerPage ? postsPerPage : loadItems.length;
        const postIDs = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < counter; i++) {
            postIDs.push(loadItems[i].dataset.post);
        }

        postIDs.forEach((postID) => {
            const clearItem = document.querySelector(`[data-post="${postID}"]`);
            clearItem.parentNode.removeChild(clearItem);
        });

        doRemoveMoreButton = loadItems.length < postsPerPage;

        return postIDs;
    }

    function getApiUrl(offset, pageType) {
        let apiUrl = '';

        if (pageType && pageType === 'archive') {
            const {taxName, taxId} = archiveMoreButton.dataset;
            apiUrl = `/wp-json/juve/v1/archive/?tax_name=${taxName}&tax_id=${taxId}&offset=${offset}`;
        } else if (pageType && pageType === 'sponsored') {
            const {searchType, searchKey} = archiveMoreButton.dataset;
            // eslint-disable-next-line max-len
            apiUrl = `/wp-json/juve/v1/sponsoredposts/?offset=${offset}&search_type=${searchType}&search_key=${searchKey}`;
        } else if (pageType && pageType === 'author') {
            const {authorId} = archiveMoreButton.dataset;
            apiUrl = `/wp-json/juve/v1/authorposts/?author_id=${authorId}&offset=${offset}`;
        } else if (pageType && pageType === 'profiles') {
            const {postType, postsPerPage, targetClass} = archiveMoreButton.dataset;
            const postIDs = getPostIDs(targetClass, postsPerPage);
            apiUrl = `/wp-json/juve/v1/profiles/?postType=${postType}&posts=${JSON.stringify(postIDs)}`;
        } else if (pageType && pageType === 'search') {
            const {postType, postsPerPage, page} = archiveMoreButton.dataset;
            // eslint-disable-next-line max-len
            apiUrl = `/wp-json/juve/v1/search/${window.location.search}&postType=${postType}&page=${page}&postsPerPage=${postsPerPage}`;
        }

        return apiUrl;
    }

    archiveMoreButton.addEventListener('click', () => {
        const {offset, page, pageType} = archiveMoreButton.dataset;
        const apiUrl = getApiUrl(offset, pageType);

        if (!apiUrl) {
            return;
        }

        toggleMoreButton();

        fetch(apiUrl, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                if (!data) {
                    return;
                }

                if (pageType && pageType !== 'profiles') {
                    doRemoveMoreButton = data.lastPostsForQuery;
                    archiveMoreButton.dataset.offset = Number(offset) + 10;

                    if (pageType === 'search') {
                        archiveMoreButton.dataset.page = Number(page) + 1;
                    }
                }

                if (doRemoveMoreButton) {
                    removeMoreButton();
                } else {
                    toggleMoreButton();
                }

                archivePostWrapper.insertAdjacentHTML('beforeend', data.html);

                // nachgeladenes HTML kann lazy zu ladende Bilder enthalten
                const newImages = document.querySelectorAll('main noscript.loading-lazy');
                // eslint-disable-next-line no-undef
                if (newImages && loadingAttributePolyfill) {
                    newImages.forEach((newImage) => {
                        // eslint-disable-next-line no-undef
                        loadingAttributePolyfill.prepareElement(newImage);
                    });
                }

                // nachgeladenes HTML kann Tooltips enthalten, die mit tippy.js intialisiert werden müssen
                const initTooltipsEvent = new Event('init-tippy-tooltips');
                window.dispatchEvent(initTooltipsEvent);

                const newFirstPost = document.querySelector('.js-first-new-post');
                if (newFirstPost) {
                    newFirstPost.focus();
                    newFirstPost.classList.remove('js-first-new-post');
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error('Error:', error);
            });
    });
}
