export default function randomList() {
    const lists = document.querySelectorAll('.js-random-list');

    lists.forEach((list) => {
        let collapsibleTargets = 0;
        const {count} = list.dataset || null;

        /* Liste zufällig sortieren */
        // eslint-disable-next-line no-plusplus
        for (let i = list.children.length; i >= 0; i--) {
            // eslint-disable-next-line no-bitwise
            const element = list.children[Math.random() * i | 0];

            if (element.classList.contains('collapsible__target')) {
                element.classList.remove('collapsible__target');
                // eslint-disable-next-line no-plusplus
                collapsibleTargets++;
            }

            list.appendChild(element);
        }

        /* Falls es eine Limitierung der Liste gibt, wird die Liste nun gekürzt */
        if (count) {
            // eslint-disable-next-line no-plusplus
            for (let i = list.children.length - 1; i > count - 1; i--) {
                const element = list.children[i];
                list.removeChild(element);
            }
        }

        /* Falls die Liste ein Collapsible ist, haben wir die Anzahl der eingeklappten Elemente
         * oben bereits eingesammelt und hängen sie hier noch einmal an die Liste an. */
        if (collapsibleTargets) {
            // eslint-disable-next-line no-plusplus
            for (let i = list.children.length - 1; i > list.children.length - 1 - collapsibleTargets; i--) {
                list.children[i].classList.add('collapsible__target');
            }
        }
    });
}
