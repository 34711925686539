export default function secondaryNavigationToggle() {
    const toggleButton = document.querySelector('.js-secondary-navigation-toggle');

    if (toggleButton) {
        toggleButton.setAttribute('aria-expanded', 'false');
        toggleButton.setAttribute('aria-label', 'Untermenü öffnen');

        const submenu = toggleButton.parentNode.querySelector('.js-secondary-navigation-submenu');

        toggleButton.addEventListener('click', () => {
            submenu.classList.toggle('is-open');

            if (toggleButton.getAttribute('aria-expanded') === 'true') {
                toggleButton.setAttribute('aria-expanded', 'false');
                toggleButton.setAttribute('aria-label', 'Untermenü öffnen');
            } else {
                toggleButton.setAttribute('aria-expanded', 'true');
                toggleButton.setAttribute('aria-label', 'Untermenü schließen');
            }
        });
    }
}
