import polyfillToggleAttribute from './modules/polyfillToggleAttribute';
import navigationToggle from './modules/navigationToggle';
import secondaryNavigationToggle from './modules/secondaryNavigationToggle';
import carousel from './modules/carousel';
import dropdown from './modules/dropdown';
import referrerRedirect from './modules/referrerRedirect';
import collapsible from './modules/collapsible';
import collapsibleSingle from './modules/collapsible-single';
import jobList from './modules/jobList';
import dynamicCardCarouselContent from './modules/dynamicCardCarouselContent';
import stickySidebar from './modules/stickySidebar';
import archiveMore from './modules/archiveMore';
import changeSubmit from './modules/changeSubmit';
import dynamicGradient from './modules/dynamicGradient';
import deactivateRadio from './modules/deactivateRadio';
import toggleToc from './modules/toggleToc';
import alphabet from './modules/alphabet';
import juveSearch from './modules/juveSearch';
import rankings from './modules/rankings';
import advertising from './modules/advertising';
import scrollTop from './modules/scrollTop';
import sponsoredFilterForm from './modules/sponsoredFilterForm';
import openAuthorBox from './modules/openAuthorBox';
import randomList from './modules/randomList';
import factBlock from './modules/factBlock';
import toast from './modules/toast';
import juvePlusErrorsEventListener from './modules/juvePlusErrorsEventListener';

window.addEventListener('load', sponsoredFilterForm);
window.addEventListener('load', polyfillToggleAttribute());
window.addEventListener('load', navigationToggle());
window.addEventListener('load', secondaryNavigationToggle());
window.addEventListener('load', carousel());
window.addEventListener('load', collapsible());
window.addEventListener('load', collapsibleSingle());
window.addEventListener('load', dropdown());
window.addEventListener('load', referrerRedirect());
window.addEventListener('load', jobList());
window.addEventListener('load', stickySidebar());
window.addEventListener('load', archiveMore());
window.addEventListener('load', changeSubmit());
window.addEventListener('load', dynamicGradient());
window.addEventListener('load', deactivateRadio());
window.addEventListener('load', toggleToc());
window.addEventListener('load', alphabet());
window.addEventListener('load', juveSearch());
window.addEventListener('load', rankings());
window.addEventListener('load', advertising());
window.addEventListener('load', scrollTop());
window.addEventListener('load', openAuthorBox());
window.addEventListener('load', randomList());
window.addEventListener('load', factBlock());
window.addEventListener('load', toast());
window.addEventListener('load', juvePlusErrorsEventListener());

window.addEventListener(
    'load',
    dynamicCardCarouselContent(
        '.js-profile-wrapper',
        '.js-profile-carousel',
        // eslint-disable-next-line no-undef
        `${juve.rest_url}/juve/v1/profile/`,
    ),
);

window.addEventListener(
    'load',
    dynamicCardCarouselContent(
        '.js-berater-wrapper',
        '.js-berater-carousel',
        // eslint-disable-next-line no-undef
        `${juve.rest_url}/juve/v1/berater/`,
    ),
);
