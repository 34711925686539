export default () => {
    const collapsibleToggles = document.querySelectorAll('.js-collapsible-toggle');

    function openCollapsible(collapsibleButton) {
        const collapsible = collapsibleButton.closest('.js-collapsible');
        collapsible.classList.toggle('open');
        if (collapsible.classList.contains('open')) {
            collapsible.setAttribute('aria-expanded', 'true');
        } else {
            collapsible.setAttribute('aria-expanded', 'false');
        }
    }

    function toggleCollapsible(event) {
        event.preventDefault();
        openCollapsible(event.target);
    }

    collapsibleToggles.forEach((collapsibleToggle) => {
        collapsibleToggle.addEventListener('click', toggleCollapsible);

        if (window.location.hash && window.location.hash.replace('#', '') === collapsibleToggle.id) {
            window.scrollTo({
                top: collapsibleToggle.getBoundingClientRect().top + window.pageYOffset - 150,
            });
            openCollapsible(collapsibleToggle);
        }
    });
};
