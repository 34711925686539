export default function dynamicGradient() {
    const gradientContainers = document.querySelectorAll('.js-gradient');
    const tableContainers = document.querySelectorAll('.wp-block-table, .table-responsive');

    function listScroll(event) {
        let list = event;
        if (event.target) {
            list = event.target;
        }

        const container = list.parentElement;

        if (list.scrollWidth <= list.offsetWidth) {
            return;
        }

        if (list.scrollLeft === 0) {
            container.classList.remove('gradient--left');
        } else {
            container.classList.add('gradient--left');
        }

        if (list.scrollLeft === (list.scrollWidth - list.offsetWidth)) {
            container.classList.remove('gradient--right');
        } else {
            container.classList.add('gradient--right');
        }
    }

    gradientContainers.forEach((container) => {
        const gradientList = container.querySelector('.js-gradient-list');
        gradientList.addEventListener('scroll', listScroll);
        listScroll(gradientList);
    });

    tableContainers.forEach((container) => {
        const tableWrapper = document.createElement('div');
        tableWrapper.classList.add('responsive-table__wrapper');
        const table = container.querySelector('table');
        const parent = table.parentElement;
        parent.prepend(tableWrapper);
        tableWrapper.appendChild(table);
        tableWrapper.addEventListener('scroll', listScroll);
        listScroll(tableWrapper);
    });
}
