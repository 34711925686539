export default function juveSearch() {
    const filterLink = document.querySelector('.js-use-filter');
    const resetLink = document.querySelector('.js-reset-filter');
    const form = document.querySelector('.js-search-form');
    const formFilters = [...document.querySelectorAll('.js-search-checkbox')];
    const postsFields = [...document.querySelectorAll('.js-post-checkbox')];
    const usePostsField = document.querySelector('.js-use-posts-field');
    const usePostsInput = document.querySelector('.js-use-posts');
    const clearIcon = document.querySelector('.js-clear-icon');
    const searchInput = document.querySelector('.js-search-input');

    const useRankingsField = document.querySelector('.js-use-rankings-field');
    const useRankingsInput = document.querySelector('.js-use-rankings');
    const rankingFields = [...document.querySelectorAll('.js-ranking-checkbox')];

    function setHiddenInputFalse(formFilter) {
        const hiddenInputName = formFilter.name.replace('-field', '');
        const hiddenInput = document.querySelector(`[data-name=${hiddenInputName}]`);
        if (hiddenInput) {
            hiddenInput.value = formFilter.checked ? 1 : 0;
        }
    }

    const showClearIcon = () => {
        if (searchInput.value.length) {
            clearIcon.classList.add('show');
            return;
        }

        clearIcon.classList.remove('show');
    };

    const deactivateUsePostsFilter = () => {
        const activePostFilters = postsFields.filter((postField) => postField.checked);
        if (!activePostFilters.length) {
            usePostsField.checked = false;
            usePostsInput.value = 0;
        }
    };

    const deactivateUseRankingsilter = () => {
        const activeRankingFilters = rankingFields.filter((rankingField) => rankingField.checked);
        if (!activeRankingFilters.length) {
            useRankingsField.checked = false;
            useRankingsInput.value = 0;
        }
    };

    if (usePostsField) {
        usePostsField.addEventListener('change', () => {
            usePostsInput.value = usePostsField.checked ? 1 : 0;
            postsFields.forEach((filter) => {
                // eslint-disable-next-line no-param-reassign
                if (!filter.checked && usePostsField.checked) {
                    // eslint-disable-next-line no-param-reassign
                    filter.checked = true;
                }
                if (filter.checked && !usePostsField.checked) {
                    // eslint-disable-next-line no-param-reassign
                    filter.checked = false;
                }
                setHiddenInputFalse(filter);
            });
        });
    }

    if (formFilters) {
        formFilters.forEach((filter) => {
            filter.addEventListener('change', () => {
                setHiddenInputFalse(filter);
            });
        });
    }

    if (useRankingsField) {
        useRankingsField.addEventListener('change', () => {
            useRankingsInput.value = useRankingsField.checked ? 1 : 0;
            rankingFields.forEach((filter) => {
                // eslint-disable-next-line no-param-reassign
                if (!filter.checked && useRankingsField.checked) {
                    // eslint-disable-next-line no-param-reassign
                    filter.checked = true;
                }
                if (filter.checked && !useRankingsField.checked) {
                    // eslint-disable-next-line no-param-reassign
                    filter.checked = false;
                }
                setHiddenInputFalse(filter);
            });
        });
    }

    rankingFields.forEach((filter) => {
        filter.addEventListener('change', () => {
            setHiddenInputFalse(filter);
            if (filter.classList.contains('js-ranking-checkbox') && filter.checked && !useRankingsField.checked) {
                // eslint-disable-next-line no-param-reassign
                useRankingsField.checked = true;
                // eslint-disable-next-line no-param-reassign
                useRankingsInput.value = 1;
            } else {
                deactivateUseRankingsilter();
            }
        });
        setHiddenInputFalse(filter);
    });

    postsFields.forEach((filter) => {
        filter.addEventListener('change', () => {
            setHiddenInputFalse(filter);
            if (filter.classList.contains('js-post-checkbox') && filter.checked && !usePostsField.checked) {
                // eslint-disable-next-line no-param-reassign
                usePostsField.checked = true;
                // eslint-disable-next-line no-param-reassign
                usePostsInput.value = 1;
            } else {
                deactivateUsePostsFilter();
            }
        });
        setHiddenInputFalse(filter);
    });

    if (filterLink) {
        filterLink.addEventListener('click', () => {
            form.submit();
        });
    }

    if (resetLink) {
        resetLink.addEventListener('click', () => {
            [...formFilters, ...postsFields, ...rankingFields].forEach((filter) => {
                if (!filter.checked) {
                    // eslint-disable-next-line no-param-reassign
                    filter.checked = true;
                    filter.dispatchEvent(new Event('change'));
                }
            });
        });
    }

    if (clearIcon && searchInput) {
        showClearIcon();
        searchInput.addEventListener('change', showClearIcon);
        searchInput.addEventListener('keyup', showClearIcon);
        clearIcon.addEventListener('click', () => {
            searchInput.value = '';
            showClearIcon();
            searchInput.focus();
        });
    }
}
