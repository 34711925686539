export default () => {
    const filters = document.querySelectorAll('.js-sponsored-filter');

    filters.forEach((filter) => {
        filter.addEventListener('change', () => {
            const active = document.querySelector('.js-sponsored-filter.active');
            if (active && active !== filter) {
                const options = active.querySelectorAll('option');
                active.classList.remove('active');
                active.classList.add('not-active');
                options.forEach((option, index) => {
                    if (option.selected && index !== 0 && filter !== option) {
                        // eslint-disable-next-line no-param-reassign
                        option.selected = false;
                    }
                });
                options[0].selected = true;
            }
            filter.classList.remove('not-active');
            filter.classList.add('active');
        });
    });
};
